import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import 'react-notifications/lib/notifications.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {NotificationContainer} from 'react-notifications';

import App from './App';
import AuthProvider from "./contexts/auth";

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <App/>
      <NotificationContainer />
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);

